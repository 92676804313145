import '../stylesheets/application'

import 'jquery'
import 'bootstrap'

import '../javascripts/purpose'

import '../javascripts/init'

import '../javascripts/styxies/main_page'

import '../javascripts/main_page'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import '../images/logo.png'
